import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { Editable, Slate } from 'slate-react'
import { Emoji } from 'emoji-mart'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import useSWR, { useSWRConfig } from 'swr'
import { COMMUNITY_API_PREFIX } from '../../../shared/api/constants/api-constants'
import { httpClient } from '../../../shared/api/http-client'
import Modal from '../../../shared/components/modal'
import { BadRequest } from '../../../shared/errors/bad-request'
import { InternalError } from '../../../shared/errors/internal-error'
import EmojiIcon from '../../../shared/icons/emoji-icon'
import ImageIcon from '../../../shared/icons/image-icon'
import PlusIcon from '../../../shared/icons/plus-icon'
import FormInput from 'shared/components/form-input'
import ImageUploader from 'shared/components/image-uploader'
import PrimaryButton from 'shared/components/primary-button'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import { generatePathFromName } from 'modules/community/hooks/path-utils'
import HoveringToolbar from '../../post/horizontal-toolbar'
import { PostStatusEnum } from '../enums/post-status-enum'
import { insertImage } from '../hooks/slate-editor-utils'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'
import { PostInterface } from '../types/post-interface'
import { MentionSuggestions } from './editor/elements/suggestion-mention/suggestion-mention'
import { useEditor } from './editor/hooks/use-editor'
import { insertMention } from './editor/utils/mention'
import TopicSelect from './topic-select'

function AddPost() {
  const EmojiPicker = dynamic(() => import('./emoji-picker'))
  const { t } = useTranslation()
  const router = useRouter()
  const { topicPath } = router.query
  const [opened, setOpened] = useState(false)
  const [title, setTitle] = useState('')
  const [topicError, setTopicError] = useState('')
  const [path, setPath] = useState('')
  const [titleError, setTitleError] = useState('')
  const [pathError, setPathError] = useState('')
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)
  const [commonError, setCommonError] = useState('')
  const { data: community, isValidating } = useCommunityWithTopics()
  const [topicId, setTopicId] = useState<number>()
  const { mutate } = useSWRConfig()
  const { mutate: mutatePostPending } = useSWR<PostInterface[]>('pending-posts')
  const selectedTopic = community?.topics.find(t => t.id === topicId)

  const {
    editor,
    content,
    serializedTextHtml,
    target,
    suggestions,
    suggestionsLoading,
    contentError,
    insertEmoji,
    setContent,
    setTarget,
    setContentError,
    updateEditorContent,
    renderElement,
    renderLeaf,
  } = useEditor({
    initialContent: [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
    path,
  })

  function convertNameToPath(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      setPath(generatePathFromName(title))
    }
  }

  const handleAddEmoji = (emoji: Emoji) => {
    setEmojiPickerVisible(false)
    // @ts-ignore
    insertEmoji(emoji.native as string)
  }

  const clearForm = () => {
    setTitle('')
    setPath('')
    setContent([
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ])
    setTopicId(undefined)
  }

  const clearErrors = () => {
    setTitleError('')
    setPathError('')
    setTopicError('')
    setContentError('')
    setCommonError('')
    setCommonError('')
  }

  const handleSendForm = async () => {
    if (!community) {
      return
    }

    clearErrors()

    try {
      const { data } = await httpClient.post<PostInterface>(
        `${COMMUNITY_API_PREFIX}/${community.path}/post`,
        {
          title,
          path,
          content: serializedTextHtml,
          topic: topicId,
        },
      )
      if (data.status === PostStatusEnum.Approved) {
        if (selectedTopic && selectedTopic.path === topicPath) {
          await mutate<PostInterface[]>('topic-posts', prev => (prev ? [data, ...prev] : [data]))
        } else {
          await mutate<PostInterface[]>('posts', prev => (prev ? [data, ...prev] : [data]))
        }
      } else {
        toast.success(t('community.add_post.sent_to_approval_message'))
        await mutatePostPending((prevPosts = []) => [...prevPosts, data], false)
      }
      setOpened(false)
      clearForm()
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors?.fields?.title) {
          setTitleError(e.errors?.fields?.title.join('\n'))
        }
        if (e.errors?.fields?.path) {
          setPathError(e.errors?.fields?.path.join('\n'))
        }
        if (e.errors?.fields?.topic) {
          setTopicError(e.errors?.fields?.topic.join('\n'))
        }
        if (e.errors?.fields?.content) {
          setContentError(e.errors?.fields?.content.join('\n'))
        }
        if (e.errors?.common) {
          setCommonError(e.errors.common.join('\n'))
        }
      } else if (e instanceof InternalError) {
        setCommonError(t('core.error.internal_error_message'))
      }
    }
  }

  if (!community || isValidating) {
    return <div className="mb-10 h-28 animate-pulse rounded-lg bg-gray" />
  }

  function updatePostTitle(e: React.ChangeEvent<HTMLInputElement>) {
    setTitleError('')
    setTitle(e.target.value)
  }

  function updatePath(e: React.ChangeEvent<HTMLInputElement>) {
    setPathError('')
    setPath(e.target.value)
  }

  function updateTopicId(value: number) {
    setTopicId(value)
    setTopicError('')
  }

  return (
    <>
      <div className="mb-8 flex flex-col items-end bg-white p-5 lg:rounded-lg">
        <textarea
          className="h-14 w-full cursor-pointer resize-none overflow-hidden rounded-md border border-bluegray/40 p-4 focus:outline-none"
          placeholder={t('home.add_post.text_area.placeholder')}
          onClick={() => setOpened(true)}
        />
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('settings.create_post.modal.title')}
      >
        <div className="mb-6">
          <label className="relative font-medium">
            <FormInput
              maxLength={256}
              value={title}
              onChange={updatePostTitle}
              label={t('home.add_post.title')}
            />
            <span className="absolute right-1 top-9 text-darkblue/50">{title.length} / 256</span>
          </label>
          {titleError && <p className="mt-2 text-sm text-red">{titleError}</p>}
        </div>
        <div className="mb-6">
          <label className="relative font-medium">
            <FormInput
              maxLength={32}
              value={path}
              onChange={updatePath}
              onFocus={convertNameToPath}
              label={t('home.add_post.path')}
            />
            <span className="absolute right-1 top-9 text-darkblue/50">{path.length} / 32</span>
          </label>
          {pathError && <p className="mt-2 text-sm text-red">{pathError}</p>}
        </div>
        <div className="mb-6">
          <TopicSelect onChange={updateTopicId} error={topicError} value={topicId} />
        </div>
        <div className="mb-6">
          <label className="color-text-black font-medium">
            <span>{t('home.add_post.content')}</span>
            <Slate editor={editor} initialValue={content} onChange={updateEditorContent}>
              <HoveringToolbar />
              <Editable
                style={{
                  border: 'solid',
                  borderColor: '#dce0e9',
                  borderRadius: '5px',
                  borderWidth: '1px',
                  maxHeight: '300px',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  padding: '15px',
                  minHeight: '200px',
                  backgroundColor: 'rgb(249, 251, 252)',
                }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
              />
              {target && (suggestionsLoading || (suggestions && suggestions.length > 0)) && (
                <MentionSuggestions
                  target={target}
                  editor={editor}
                  items={suggestions || []}
                  isLoading={suggestionsLoading}
                  onSelect={char => {
                    if (target) {
                      insertMention(
                        editor,
                        {
                          displayName: char.displayName,
                          id: char.userId,
                          profileImageUrl: char.profileImageUrl,
                        },
                        target,
                      )
                      setTarget(null)
                    }
                  }}
                />
              )}
            </Slate>
          </label>
          {contentError && <p className="mt-2 text-sm text-red">{contentError}</p>}
        </div>
        <div className="mb-6 flex items-center">
          <EmojiIcon
            className="mr-2 cursor-pointer fill-bluegray"
            onClick={() => setEmojiPickerVisible(prev => !prev)}
          />
          {emojiPickerVisible && (
            <EmojiPicker
              setEmojiPickerVisible={setEmojiPickerVisible}
              onEmojiSelect={handleAddEmoji}
            />
          )}
          <ImageUploader
            imageCropperProps={{
              // NOTE: This needs for a crop box has a free ratio - https://github.com/fengyuanchen/cropperjs?tab=readme-ov-file#aspectratio
              aspectRatio: NaN,
            }}
            fileSelectorDropZoneClassName="p-0 h-auto w-min"
            icon={<ImageIcon className="cursor-pointer fill-bluegray" />}
            acceptTypes={fileInputAcceptTypes.jpg_jpeg_png}
            onUploadSuccess={dataFile => {
              insertImage(editor, dataFile.path)
            }}
          />
          <PrimaryButton className="ml-auto" onClick={handleSendForm}>
            <PlusIcon />
            {t('home.add_post.button.title')}
          </PrimaryButton>
        </div>
        <div>{commonError && <p className="mt-2 text-sm text-red">{commonError}</p>}</div>
      </Modal>
    </>
  )
}

export default AddPost
